/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'

import React, { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import {  
  User, 
  Briefcase, 
  Brain,
  ClipboardList,
  Target,
  Info,
  Building2,
  BarChart2,
  GitBranch,
  Star,
  StarHalf
} from 'lucide-react'

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import api from '../api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./ui/dialog"
import { Button } from "./ui/button"
import { Checkbox } from "./ui/checkbox"
import { cn } from "../lib/utils"

// Update types to match the new schema
type KPI = {
  id: number;
  description: string;
  needs_review: boolean;
}

type KRA = {
  id: number;
  description: string;
  needs_review: boolean;
  responsibilities: Array<string | { description: string }>;
}

type Decision = {
  id: number;
  description: string;
  status: string;
  decision_priority: string;
  deadline: string;
  decision_level: string;
  inform: Array<{ employee_id: string; name: string }>;
  advise: Array<{ employee_id: string; name: string }>;
  decide: Array<{ employee_id: string; name: string }>;
  execute: Array<{ employee_id: string; name: string }>;
}

type Role = {
  id: number;
  title: string;
  department: string;
  sub_department_level_1?: string;
  sub_department_level_2?: string;
  cost_center?: string;
  location: string;
  grade: string;
  reporting_to: {
    id: number;
    title: string;
    current_employee: {
      id: number;
      name: string;
      employee_id: string;
    } | null;
  } | null;
  kras: KRA[];
  kpis: KPI[];
  goals: Goal[];
}

type Employee = {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string | null;
  is_active: boolean;
  competencies: Array<string | { description: string }>;
}

type EmployeeData = {
  employee: Employee;
  role: Role;
  decisions: Decision[];
  has_acknowledged_kras: boolean;
}

type GoalUpdate = {
  id: number;
  type: "self" | "internal" | "external";
  content: string;
  progress: number;
  raters: string[];
  review_status: 'no_review' | 'pending' | 'awaiting_feedback' | 'completed';
  created_at: string;
  review_requests: ReviewRequest[];
};

type ReviewRequest = {
  reviewer_email: string;
  status: 'pending' | 'completed';
  rating?: number;
  comment?: string;
  created_at: string;
  completed_at?: string;
};

type Goal = {
  id: number;
  title: string;
  description: string;
  goal_type: 'personal' | 'company';
  progress: number;
  owner: string | null;
  owner_details: {
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  } | null;
  internal_reviewer_details: Array<{
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  }>;
  external_reviewers: string[];
  timeline: string;
  timeline_duration: string;
  timeline_year: number;
  company: string;
  created_at: string;
  updated_at: string;
  updates: GoalUpdate[];
  parent_goal?: number | null;
  parent_goal_details?: {
    id: number;
    title: string;
    progress: number;
  } | null;
  isExpanded?: boolean;
  updateExpanded?: boolean;
  average_rating?: number;
  total_reviews?: number;
};

const priorityColors: { [key: string]: string } = {
  'Critical': 'text-red-600 bg-red-50',
  'High': 'text-orange-600 bg-orange-50',
  'Medium': 'text-yellow-600 bg-yellow-50',
  'Low': 'text-green-600 bg-green-50'
}

const decisionLevelColors: { [key: string]: string } = {
  'Strategic': 'text-purple-600 bg-purple-50',
  'Tactical': 'text-indigo-600 bg-indigo-50',
  'Routine': 'text-blue-600 bg-blue-50'
}

export default function EmployeeDashboard() {
  const { user, updateUser } = useAuth()
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [showAcknowledgmentModal, setShowAcknowledgmentModal] = useState(false)
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  const [personalGoals, setPersonalGoals] = useState<Goal[]>([]);
  const [activeSection, setActiveSection] = useState<'goals' | 'kras' | 'kpis' | 'decisions' | 'competencies' | 'details'>('goals');

  // Fetch employee data
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (!user?.employeeId) {
        setError('Employee ID not found. Please log in again.')
        setLoading(false)
        return
      }

      try {
        const response = await api.get(`/api/employee-dashboard/${user.employeeId}/`)
        setEmployeeData(response.data)
      } catch (err) {
        console.error('Error fetching employee data:', err)
        setError('Failed to load employee data. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    fetchEmployeeData()
  }, [user])

  // Show acknowledgment modal if needed
  useEffect(() => {
    if (user && user.role === 'employee' && !user.hasAcknowledgedKras) {
      setShowAcknowledgmentModal(true);
    } else {
      setShowAcknowledgmentModal(false);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasAcknowledgedKras]);

  // Fetch personal goals
  useEffect(() => {
    const fetchPersonalGoals = async () => {
      if (!user?.employeeId || !user?.company) return;

      try {
        const response = await api.get(`/api/companies/${user.company}/goals/`, {
          params: {
            type: 'personal',
            employee_id: user.employeeId,
            include_updates: true
          }
        });
        setPersonalGoals(response.data.map((goal: Goal) => ({
          ...goal,
          isExpanded: false,
          updateExpanded: false
        })));
      } catch (error) {
        console.error('Error fetching personal goals:', error);
      }
    };

    fetchPersonalGoals();
  }, [user?.employeeId, user?.company]);

  const handleAcknowledgment = async () => {
    try {
      const response = await api.post('/api/accounts/acknowledge-kras/', {
        userId: user?.userId
      });
      
      if (response.data.status === 'success') {
        setShowAcknowledgmentModal(false);
        if (user) {
          const updatedUser = { ...user, hasAcknowledgedKras: true };
          updateUser(updatedUser);
        }
      }
    } catch (error) {
      console.error('Error acknowledging KRAs:', error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-600">{error}</div>
  }

  if (!employeeData) {
    return <div className="flex justify-center items-center h-screen">No employee data available.</div>
  }

  const { employee, role, decisions } = employeeData

  const categorizedDecisions = {
    I: decisions.filter(d => d.inform?.some(e => e.employee_id === employee.employee_id) ?? false),
    A: decisions.filter(d => d.advise?.some(e => e.employee_id === employee.employee_id) ?? false),
    D: decisions.filter(d => d.decide?.some(e => e.employee_id === employee.employee_id) ?? false),
    E: decisions.filter(d => d.execute?.some(e => e.employee_id === employee.employee_id) ?? false),
  }

  // Use combinedGoals unconditionally in the rendering
  
  return (
    <div className="flex flex-col h-screen bg-background">
      {/* Header Bar */}
      <div className="sticky top-0 z-20 bg-background border-b">
        <div className="container mx-auto px-4 py-4 max-w-8xl">
          <div className="flex items-center justify-between">
            {/* Employee Info */}
            <div className="flex items-center gap-3">
              <div>
                <h1 className="text-2xl font-bold">{employee.name}</h1>
                <p className="text-sm text-muted-foreground">{role.title} • {role.department}</p>
              </div>
            </div>

            {/* Navigation Buttons */}
            <div className="flex items-center gap-2">
              <Button
                variant={activeSection === 'goals' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('goals')}
                className="flex items-center gap-2 text-base"
              >
                <Target className="h-5 w-5" />
                <span>Goals</span>
              </Button>

              <Button
                variant={activeSection === 'kras' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('kras')}
                className="flex items-center gap-2 text-base"
              >
                <ClipboardList className="h-5 w-5" />
                <span>KRAs</span>
              </Button>

              <Button
                variant={activeSection === 'kpis' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('kpis')}
                className="flex items-center gap-2 text-base"
              >
                <BarChart2 className="h-5 w-5" />
                <span>KPIs</span>
              </Button>

              <Button
                variant={activeSection === 'decisions' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('decisions')}
                className="flex items-center gap-2 text-base"
              >
                <GitBranch className="h-5 w-5" />
                <span>Decisions</span>
              </Button>

              <Button
                variant={activeSection === 'competencies' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('competencies')}
                className="flex items-center gap-2 text-base"
              >
                <Brain className="h-5 w-5" />
                <span>Competencies</span>
              </Button>

              <Button
                variant={activeSection === 'details' ? 'default' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection('details')}
                className="flex items-center gap-2 text-base"
              >
                <Info className="h-5 w-5" />
                <span>Details</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto px-4 py-6 max-w-8xl">
          {activeSection === 'goals' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Goals</h2>
              {personalGoals.filter(goal => goal.owner === user?.employeeId || goal.owner_details?.employee_id === user?.employeeId).length > 0 ? (
                <div className="grid gap-4">
                  {personalGoals
                    .filter(goal => goal.owner === user?.employeeId || goal.owner_details?.employee_id === user?.employeeId)
                    .map((goal) => (
                    <Card key={goal.id}>
                      <CardContent className="pt-6">
                        <div className="space-y-4">
                          <div className="flex justify-between items-start">
                            <div className="flex-1">
                              <h3 className={cn(
                                "text-base font-medium whitespace-pre-wrap",
                                !goal.isExpanded && "line-clamp-2"
                              )}>
                                {goal.description}
                              </h3>
                              {goal.description.split('\n').length > 2 && (
                                <button
                                  onClick={() => {
                                    setPersonalGoals(prev => prev.map(g => 
                                      g.id === goal.id ? { ...g, isExpanded: !g.isExpanded } : g
                                    ));
                                  }}
                                  className="text-sm text-muted-foreground hover:text-foreground mt-1"
                                >
                                  {goal.isExpanded ? 'Show less' : 'Show more'}
                                </button>
                              )}
                            </div>
                            {goal.parent_goal_details && (
                              <span className="text-sm text-muted-foreground ml-4 flex-shrink-0">
                                Parent: {goal.parent_goal_details.title}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <div className="w-full bg-secondary rounded-full h-2">
                              <div 
                                className="bg-primary h-full rounded-full transition-all"
                                style={{ width: `${goal.progress}%` }}
                              />
                            </div>
                            <div className="flex justify-between text-sm text-muted-foreground">
                              <span>{goal.progress}% complete</span>
                              <span>{goal.timeline}</span>
                            </div>
                          </div>
                          {goal.average_rating !== undefined && goal.average_rating !== null && (
                            <div className="flex items-center gap-2">
                              <div className="flex items-center">
                                {[1, 2, 3, 4, 5].map((star) => {
                                  const rating = goal.average_rating!;
                                  return (
                                    <span key={star} className="text-yellow-400">
                                      {rating >= star ? (
                                        <Star className="w-4 h-4 fill-current" />
                                      ) : rating >= star - 0.5 ? (
                                        <StarHalf className="w-4 h-4 fill-current" />
                                      ) : (
                                        <Star className="w-4 h-4" />
                                      )}
                                    </span>
                                  );
                                })}
                              </div>
                              <span className="text-sm text-muted-foreground">
                                {goal.average_rating?.toFixed(1)} ({goal.total_reviews || 0} {(goal.total_reviews || 0) === 1 ? 'review' : 'reviews'})
                              </span>
                            </div>
                          )}
                          {goal.updates.length > 0 && (
                            <div className="mt-4 pt-4 border-t">
                              <p className="text-sm font-medium mb-2">Latest Update</p>
                              <div>
                                <p className={cn(
                                  "text-sm text-muted-foreground whitespace-pre-wrap",
                                  !goal.updateExpanded && "line-clamp-2"
                                )}>
                                  {goal.updates[0].content}
                                </p>
                                {goal.updates[0].content.split('\n').length > 2 && (
                                  <button
                                    onClick={() => {
                                      setPersonalGoals(prev => prev.map(g => 
                                        g.id === goal.id ? { ...g, updateExpanded: !g.updateExpanded } : g
                                      ));
                                    }}
                                    className="text-sm text-muted-foreground hover:text-foreground mt-1"
                                  >
                                    {goal.updateExpanded ? 'Show less' : 'Show more'}
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 bg-muted/30 rounded-lg">
                  <p className="text-muted-foreground">No goals assigned yet</p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'kras' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Key Result Areas</h2>
              {role.kras?.length > 0 ? (
                <div className="grid gap-4">
                  {role.kras?.map((kra) => (
                    <Card key={kra.id}>
                      <CardContent className="pt-6">
                        <div className="space-y-4">
                          <h3 className="font-medium">{kra.description}</h3>
                          {kra.responsibilities && kra.responsibilities.length > 0 && (
                            <div className="pl-4 border-l-2 border-muted space-y-2">
                              {kra.responsibilities.map((resp, idx) => (
                                <p key={idx} className="text-[15px] text-muted-foreground">
                                  • {typeof resp === 'string' ? resp : resp.description}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 bg-muted/30 rounded-lg">
                  <p className="text-muted-foreground">No KRAs defined yet</p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'kpis' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Key Performance Indicators</h2>
              {role.kpis?.length > 0 ? (
                <div className="grid gap-4">
                  {role.kpis?.map((kpi) => (
                    <Card key={kpi.id}>
                      <CardContent className="pt-6">
                        <div className="flex items-start gap-4">
                          <div className="flex-1">
                            <p className="text-base">{kpi.description}</p>
                          </div>
                          {kpi.needs_review && (
                            <span className="text-sm bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full">
                              Needs Review
                            </span>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 bg-muted/30 rounded-lg">
                  <p className="text-muted-foreground">No KPIs defined yet</p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'decisions' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Decisions</h2>
              {Object.entries(categorizedDecisions).some(([_, decisions]) => decisions.length > 0) ? (
                Object.entries(categorizedDecisions)
                  .filter(([_, decisions]) => decisions.length > 0)
                  .map(([category, decisions]) => (
                    <div key={category} className="space-y-4">
                      <h3 className="text-sm font-medium text-muted-foreground">
                        {getCategoryFullName(category)}
                      </h3>
                      <div className="grid gap-4">
                        {decisions.map(decision => (
                          <Card key={decision.id}>
                            <CardContent className="pt-6">
                              <div className="space-y-4">
                                <p className="text-base">{decision.description}</p>
                                <div className="flex gap-2">
                                  <span className={`text-sm px-2 py-1 rounded-full ${priorityColors[decision.decision_priority]}`}>
                                    {decision.decision_priority}
                                  </span>
                                  <span className={`text-sm px-2 py-1 rounded-full ${decisionLevelColors[decision.decision_level]}`}>
                                    {decision.decision_level}
                                  </span>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="text-center py-8 bg-muted/30 rounded-lg">
                  <p className="text-muted-foreground">No decisions assigned yet</p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'competencies' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Competencies</h2>
              {employee.competencies?.length > 0 ? (
                <div className="grid gap-4">
                  {employee.competencies?.map((competency, index) => (
                    <Card key={index}>
                      <CardContent className="pt-6">
                        <p className="text-base">
                          {typeof competency === 'string' ? competency : competency.description}
                        </p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 bg-muted/30 rounded-lg">
                  <p className="text-muted-foreground">No competencies defined yet</p>
                </div>
              )}
            </div>
          )}

          {activeSection === 'details' && (
            <div className="space-y-6">
              <h2 className="text-lg font-semibold">Employee Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Personal Information Card */}
                <Card>
                  <CardHeader className="pb-3">
                    <div className="flex items-center gap-2">
                      <User className="h-5 w-5" />
                      <CardTitle className="text-base">Personal Information</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    {employee.employee_id && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Employee ID</span>
                        <span className="text-sm font-medium">{employee.employee_id}</span>
                      </div>
                    )}
                    {employee.email && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Email</span>
                        <span className="text-sm font-medium">{employee.email}</span>
                      </div>
                    )}
                    {employee.date_of_joining && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Joined</span>
                        <span className="text-sm font-medium">
                          {new Date(employee.date_of_joining).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </span>
                      </div>
                    )}
                  </CardContent>
                </Card>

                {/* Department Information Card */}
                <Card>
                  <CardHeader className="pb-3">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-5 w-5" />
                      <CardTitle className="text-base">Department Information</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-muted-foreground">Department</span>
                      <span className="text-sm font-medium">{role.department}</span>
                    </div>
                    {role.sub_department_level_1 && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Sub Department</span>
                        <span className="text-sm font-medium">{role.sub_department_level_1}</span>
                      </div>
                    )}
                    {role.sub_department_level_2 && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Unit</span>
                        <span className="text-sm font-medium">{role.sub_department_level_2}</span>
                      </div>
                    )}
                    {role.cost_center && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Cost Center</span>
                        <span className="text-sm font-medium">{role.cost_center}</span>
                      </div>
                    )}
                  </CardContent>
                </Card>

                {/* Role Information Card */}
                <Card>
                  <CardHeader className="pb-3">
                    <div className="flex items-center gap-2">
                      <Briefcase className="h-5 w-5" />
                      <CardTitle className="text-base">Role Information</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-muted-foreground">Title</span>
                      <span className="text-sm font-medium">{role.title}</span>
                    </div>
                    {role.grade && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Grade</span>
                        <span className="text-sm font-medium">{role.grade}</span>
                      </div>
                    )}
                    {role.location && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Location</span>
                        <span className="text-sm font-medium">{role.location}</span>
                      </div>
                    )}
                    {role.reporting_to?.current_employee && (
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Reports To</span>
                        <span className="text-sm font-medium">{role.reporting_to.current_employee.name}</span>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Keep the acknowledgment modal */}
      {showAcknowledgmentModal && (
        <Dialog 
          open={showAcknowledgmentModal} 
          onOpenChange={() => {}} // Prevent closing the modal without acknowledgment
        >
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>Welcome to Your Role</DialogTitle>
              <DialogDescription>
                Please review your Key Result Areas (KRAs) and Key Performance Indicators (KPIs) carefully.
                You need to acknowledge these before proceeding.
              </DialogDescription>
            </DialogHeader>
            
            <div className="max-h-[60vh] overflow-y-auto">
              <div className="space-y-4">
                <h3 className="font-semibold">Key Result Areas (KRAs)</h3>
                {role.kras?.map((kra) => (
                  <div key={kra.id} className="p-3 bg-muted rounded-lg">
                    <p>{kra.description}</p>
                    {kra.responsibilities && (
                      <ul className="mt-2 ml-4 list-disc">
                        {kra.responsibilities.map((resp, idx) => (
                          <li key={idx} className="text-sm">
                            {typeof resp === 'string' ? resp : resp.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}

                <h3 className="font-semibold mt-6">Key Performance Indicators (KPIs)</h3>
                {role.kpis?.map((kpi) => (
                  <div key={kpi.id} className="p-3 bg-muted rounded-lg">
                    <p>{kpi.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center space-x-2 mt-4">
              <Checkbox
                id="acknowledge"
                checked={isAcknowledged}
                onCheckedChange={(checked) => setIsAcknowledged(checked as boolean)}
              />
              <label
                htmlFor="acknowledge"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I acknowledge that I have read and understood my KRAs and KPIs
              </label>
            </div>

            <DialogFooter>
              <Button
                onClick={handleAcknowledgment}
                disabled={!isAcknowledged}
              >
                Acknowledge and Continue
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

function getCategoryFullName(category: string): string {
  switch (category) {
    case 'I': return 'Inform'
    case 'A': return 'Advise'
    case 'D': return 'Decide'
    case 'E': return 'Execute'
    default: return category
  }
}

