import React from 'react';
import { EdgeProps } from 'reactflow';

const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  markerEnd,
}) => {
  // Constants for edge rendering
  const VERTICAL_MARGIN = 30;
  const EDGE_RADIUS = 20;
  const ALIGNMENT_THRESHOLD = 5; // Threshold to consider nodes aligned

  const getHierarchicalPath = () => {
    // Check if nodes are vertically aligned (within threshold)
    const isVerticallyAligned = Math.abs(targetX - sourceX) < ALIGNMENT_THRESHOLD;

    if (isVerticallyAligned) {
      // Draw a straight vertical line
      return `
        M ${sourceX} ${sourceY}
        L ${sourceX} ${targetY}
      `;
    }

    // Regular curved path for non-aligned nodes
    const horizontalY = sourceY + VERTICAL_MARGIN / 2;
    const goingRight = targetX > sourceX;
    const curveDirection = goingRight ? 1 : -1;
    
    return `
      M ${sourceX} ${sourceY}
      L ${sourceX} ${horizontalY - EDGE_RADIUS}
      Q ${sourceX} ${horizontalY} ${sourceX + (EDGE_RADIUS * curveDirection)} ${horizontalY}
      L ${targetX - (EDGE_RADIUS * curveDirection)} ${horizontalY}
      Q ${targetX} ${horizontalY} ${targetX} ${horizontalY + EDGE_RADIUS}
      L ${targetX} ${targetY}
    `;
  };

  return (
    <path
      id={id}
      style={{
        ...style,
        strokeWidth: 2,
        stroke: '#555',
        fill: 'none',
      }}
      className="react-flow__edge-path"
      d={getHierarchicalPath()}
      markerEnd={markerEnd}
    />
  );
};

export default CustomEdge;
