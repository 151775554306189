"use client"

import { useState, useEffect } from "react"
import api from '../../api'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Textarea } from "../ui/textarea"
import { Button } from "../ui/button"
import { Toast, ToastProvider, ToastViewport, ToastTitle, ToastDescription } from "../ui/use-toast" // Import Toast components
import { useAuth } from '../../contexts/AuthContext';
import { Label } from "../ui/label"
import { RadioGroup, RadioGroupItem } from "../ui/radio-group"
import { Check } from "lucide-react"

export default function EmployeeEngagementTool() {
  const [moonshotIdea, setMoonshotIdea] = useState("")
  const [moonshotPlan, setMoonshotPlan] = useState("")
  const [moonshotResources, setMoonshotResources] = useState("")
  const [innovationIdea, setInnovationIdea] = useState("")
  const [innovationType, setInnovationType] = useState("")
  const [whistleblowerReport, setWhistleblowerReport] = useState("")
  const [weeklyTheme, setWeeklyTheme] = useState("")
  const [toast, setToast] = useState<{ title: string; description: string; variant?: "default" | "destructive" } | null>(null)
  const { user } = useAuth();

  useEffect(() => {
    // Simulating fetching the weekly theme
    const themes = ["Operational Efficiency"]
    const randomTheme = themes[Math.floor(Math.random() * themes.length)]
    setWeeklyTheme(randomTheme)
  }, [])

  const showToast = (title: string, description: string, variant: "default" | "destructive" = "default") => {
    setToast({ title, description, variant })
    setTimeout(() => setToast(null), 5000) // Hide toast after 5 seconds
  }

  const handleSubmit = (type: string) => {
    let payload;
    
    switch (type) {
      case 'moonshot':
        payload = {
          report_type: type,
          idea: moonshotIdea,
          theme: weeklyTheme,
          plan: moonshotPlan,
          resources: moonshotResources,
        };
        break;
      case 'innovation':
        payload = {
          report_type: type,
          idea: innovationIdea,
          type: innovationType,
        };
        break;
      case 'whistleblower':
        payload = {
          report_type: type,
          report: whistleblowerReport,
        };
        break;
      default:
        console.error('Invalid report type');
        return;
    }

    const companyName = user?.company || 'Kaynes'; // Use 'Kaynes' as fallback
    api.post(`/api/moonshots/${companyName}/collect/`, payload)
      .then(response => {
        console.log(`Submitted ${type}:`, response.data);
        // Reset form fields after submission
        if (type === 'moonshot') {
          setMoonshotIdea("");
          setMoonshotPlan("");
          setMoonshotResources("");
        } else if (type === 'innovation') {
          setInnovationIdea("");
          setInnovationType("");
        } else if (type === 'whistleblower') {
          setWhistleblowerReport("");
        }
        
        // Show success notification
        showToast("Success", "Your submission has been received.")
      })
      .catch(error => {
        console.error('Error submitting report:', error);
        // Show error notification
        showToast("Error", "There was a problem submitting your report. Please try again.", "destructive")
      });
  }

  return (
    <ToastProvider>
      <div className="p-4 bg-background min-h-screen">
        <Tabs defaultValue="moonshots" className="w-full">
          <TabsList className="w-full grid grid-cols-3 mb-6">
            <TabsTrigger 
              value="moonshots"
              className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Moonshots
            </TabsTrigger>
            <TabsTrigger 
              value="innovation"
              className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Innovations
            </TabsTrigger>
            <TabsTrigger 
              value="whistleblower"
              className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            >
              Whistleblower
            </TabsTrigger>
          </TabsList>

          <TabsContent value="moonshots">
            <Card>
              <CardHeader>
                <CardTitle>Help build the next big thing</CardTitle>
                <CardDescription>Share your idea, project plan, and resource requirements.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mb-6 rounded-lg border bg-card p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <span className="font-semibold text-sm">Weekly Theme</span>
                    <span className="px-2 py-1 bg-primary/10 text-primary rounded-md text-sm">
                      {weeklyTheme}
                    </span>
                  </div>
                  <p className="text-sm text-muted-foreground">Focus your moonshot idea around this week's theme</p>
                </div>

                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label htmlFor="moonshot-idea">Idea</Label>
                    <Input 
                      id="moonshot-idea" 
                      placeholder="Your big idea based on this week's theme" 
                      value={moonshotIdea} 
                      onChange={(e) => setMoonshotIdea(e.target.value)}
                      className="h-12"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="moonshot-plan">Project Plan</Label>
                    <Textarea 
                      id="moonshot-plan" 
                      placeholder="Outline your project plan and implementation strategy" 
                      value={moonshotPlan} 
                      onChange={(e) => setMoonshotPlan(e.target.value)}
                      className="min-h-[120px] resize-none"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="moonshot-resources">Resource Requirements</Label>
                    <Textarea 
                      id="moonshot-resources" 
                      placeholder="List the resources, team members, and tools needed" 
                      value={moonshotResources} 
                      onChange={(e) => setMoonshotResources(e.target.value)}
                      className="min-h-[120px] resize-none"
                    />
                  </div>

                  <Button 
                    onClick={() => handleSubmit('moonshot')} 
                    className="w-full h-12"
                  >
                    Submit Moonshot
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="innovation">
            <Card>
              <CardHeader>
                <CardTitle>Share your innovative ideas</CardTitle>
                <CardDescription>Help improve efficiency, profitability, or collaboration.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label htmlFor="innovation-type">Type of Submission</Label>
                    <RadioGroup
                      value={innovationType}
                      onValueChange={setInnovationType}
                      className="grid grid-cols-2 gap-4"
                    >
                      <div>
                        <RadioGroupItem
                          value="innovation"
                          id="innovation"
                          className="peer sr-only"
                        />
                        <Label
                          htmlFor="innovation"
                          className="relative flex flex-col items-center justify-between rounded-md border-2 border-muted bg-background p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary peer-data-[state=checked]:bg-primary/5 [&:has([data-state=checked])]:border-primary cursor-pointer transition-all"
                        >
                          {innovationType === 'innovation' && (
                            <span className="absolute right-2 top-2 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-primary-foreground">
                              <Check className="h-4 w-4" />
                            </span>
                          )}
                          <div className="text-sm font-semibold">Process Innovation</div>
                          <div className="text-xs text-muted-foreground">
                            Suggest new ways to improve our processes
                          </div>
                        </Label>
                      </div>

                      <div>
                        <RadioGroupItem
                          value="feedback"
                          id="feedback"
                          className="peer sr-only"
                        />
                        <Label
                          htmlFor="feedback"
                          className="relative flex flex-col items-center justify-between rounded-md border-2 border-muted bg-background p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary peer-data-[state=checked]:bg-primary/5 [&:has([data-state=checked])]:border-primary cursor-pointer transition-all"
                        >
                          {innovationType === 'feedback' && (
                            <span className="absolute right-2 top-2 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-primary-foreground">
                              <Check className="h-4 w-4" />
                            </span>
                          )}
                          <div className="text-sm font-semibold">Improvement Feedback</div>
                          <div className="text-xs text-muted-foreground">
                            Share feedback on existing processes
                          </div>
                        </Label>
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="innovation-idea">Your Idea</Label>
                    <Textarea 
                      id="innovation-idea" 
                      placeholder="Describe your innovation or improvement suggestion in detail" 
                      value={innovationIdea} 
                      onChange={(e) => setInnovationIdea(e.target.value)}
                      className="min-h-[200px] resize-none"
                    />
                  </div>

                  <Button 
                    onClick={() => handleSubmit('innovation')} 
                    className="w-full h-12"
                  >
                    Submit Innovation
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="whistleblower">
            <Card>
              <CardHeader>
                <CardTitle>Anonymous Reporting</CardTitle>
                <CardDescription>Securely report unethical practices. Your identity remains protected.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <div className="space-y-2">
                    <Label htmlFor="whistleblower-report">Report Details</Label>
                    <Textarea 
                      id="whistleblower-report" 
                      placeholder="Provide a detailed description of the situation, including relevant dates, locations, and persons involved" 
                      value={whistleblowerReport} 
                      onChange={(e) => setWhistleblowerReport(e.target.value)}
                      className="min-h-[300px] resize-none"
                    />
                  </div>

                  <Button 
                    onClick={() => handleSubmit('whistleblower')} 
                    className="w-full h-12"
                  >
                    Submit Report
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        {toast && (
          <Toast variant={toast.variant}>
            <ToastTitle>{toast.title}</ToastTitle>
            <ToastDescription>{toast.description}</ToastDescription>
          </Toast>
        )}
        <ToastViewport />
      </div>
    </ToastProvider>
  )
}
