import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Node } from 'reactflow';
import { X, Edit2, ChevronDown, ChevronRight, Briefcase, MapPin, Calendar, Users, Target, Brain, MinusCircle, PlusCircle, Edit3, Trash2, ClipboardList, LineChart, FileText, Mail, UserPlus } from 'lucide-react';
import api from '../api';
import { createPortal } from 'react-dom';
import { useFloating, offset, shift, flip } from '@floating-ui/react';
import { Search } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./ui/alert-dialog";
import { useKPI } from '../contexts/KPIContext';

interface NodeSidebarProps {
  node: Node;
  employeeDetails: any;
  onClose: () => void;
  onUpdate: (data: { name: string; role: string }) => void;
  userId: string;
  onDataChange?: () => void;
}

type EmployeeSelectorProps = {
  selectedEmployee: any;
  onUpdate: (employee: any) => void;
  onClose: () => void;
  allEmployees: any[];
};

const EmployeeSelector = ({ selectedEmployee, onUpdate, onClose, allEmployees }: EmployeeSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const filteredEmployees = allEmployees.filter(
    emp => 
      emp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      emp.employee_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white border rounded-md shadow-lg p-2 w-[300px]">
      <div className="relative mb-2">
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
        <input
          type="text"
          placeholder="Search by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-8 p-2 border rounded text-sm"
          autoComplete="off"
        />
      </div>
      <div className="max-h-60 overflow-y-auto">
        {filteredEmployees.map(employee => (
          <div
            key={employee.id}
            className={`p-2 hover:bg-gray-100 cursor-pointer ${
              selectedEmployee?.id === employee.id ? 'bg-gray-50' : ''
            }`}
            onClick={() => {
              onUpdate(employee);
              onClose();
            }}
          >
            <div className="text-sm font-medium">{employee.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface EditableTextProps {
  value: string;
  onSave: (value: string) => void;
  onCancel?: () => void;
  placeholder?: string;
  forceEdit?: boolean;
}

interface EditableItemProps {
  item: { description: string; id?: string };
  onEdit: (value: string) => void;
  onDelete: () => void;
  children?: React.ReactNode;
  itemId: string;
  editingNewItem: string | null;
  setEditingNewItem: (id: string | null) => void;
}

const EditableText: React.FC<EditableTextProps> = ({ 
  value, 
  onSave, 
  onCancel, 
  placeholder = "Enter description...", 
  forceEdit = false 
}) => {
  const [isEditing, setIsEditing] = useState(forceEdit);
  const [text, setText] = useState(value);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleSave = () => {
    if (text.trim()) {
      onSave(text.trim());
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setText(value);
    setIsEditing(false);
    onCancel?.();
  };

  if (isEditing) {
    return (
      <div className="flex flex-col gap-2">
        <textarea
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="w-full p-2 border rounded-md text-sm"
          placeholder={placeholder}
          rows={2}
          autoFocus
        />
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            className="px-2 py-1 text-xs bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
            disabled={!text.trim()}
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="px-2 py-1 text-xs border rounded-md hover:bg-muted"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="group flex items-start gap-2 cursor-pointer p-2 hover:bg-muted/50 rounded-md"
      onClick={() => setIsEditing(true)}
    >
      <span className="flex-grow">{value}</span>
      <Edit3 size={16} className="opacity-0 group-hover:opacity-100 text-muted-foreground" />
    </div>
  );
};

const EditableItem: React.FC<EditableItemProps> = ({ 
  item, 
  onEdit, 
  onDelete, 
  children, 
  itemId,
  editingNewItem,
  setEditingNewItem
}) => {
  const [isEditing, setIsEditing] = useState(editingNewItem === itemId);

  return (
    <div className="group relative bg-muted p-3 rounded-md">
      <div className="flex items-start justify-between">
        <div className="flex-grow pr-16">
          {isEditing || editingNewItem === itemId ? (
            <EditableText
              value={item.description}
              onSave={(value) => {
                onEdit(value);
                setEditingNewItem(null);
                setIsEditing(false);
              }}
              onCancel={() => {
                setEditingNewItem(null);
                setIsEditing(false);
              }}
              placeholder="Enter description..."
              forceEdit={true}
            />
          ) : (
            <div className="p-2">{item.description}</div>
          )}
        </div>
        <div className="absolute right-3 top-3 opacity-0 group-hover:opacity-100 flex items-center gap-1">
          <button
            onClick={() => setIsEditing(true)}
            className="p-1.5 text-muted-foreground hover:text-foreground rounded-md"
            title="Edit"
          >
            <Edit3 size={16} />
          </button>
          <button
            onClick={onDelete}
            className="p-1.5 text-red-600 hover:text-red-700 rounded-md"
            title="Delete"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

const createTempId = () => `temp_${Math.random().toString(36).substr(2, 9)}`;

interface Responsibility {
  id: string;
  description: string;
}

interface KRA {
  id: string;
  description: string;
  responsibilities: Responsibility[];
}

interface Employee {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string | null;
  is_active: boolean;
  competencies: string[];
}

interface Goal {
  id: number;
  title: string;
  description: string;
  progress: number;
  status: string;
  due_date: string;
  timeline?: string;
}

interface Role {
  id: number;
  title: string;
  department: string;
  sub_department_level_1?: string;
  sub_department_level_2?: string;
  cost_center?: string;
  location: string;
  grade: string;
  current_employee: Employee | null;
  reporting_to: {
    id: number;
    title: string;
    current_employee: Employee | null;
  } | null;
  kras: KRA[];
  kpis: KPI[];
  goals: Goal[];
}

interface EmployeeData {
  employee: Employee;
  role: Role;
  decisions: Decision[];
}

const NodeSidebar: React.FC<NodeSidebarProps> = ({ node, employeeDetails, onClose, onUpdate, userId, onDataChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [sidebarTop, setSidebarTop] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState("");
  const [isKRAsExpanded, setIsKRAsExpanded] = useState(false);
  const [isKPIsExpanded, setIsKPIsExpanded] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isGoalsExpanded, setIsGoalsExpanded] = useState(false);
  const [isCompetenciesExpanded, setIsCompetenciesExpanded] = useState(false);
  const [isEmployeeSelectorOpen, setIsEmployeeSelectorOpen] = useState(false);
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const { user } = useAuth();
  const [localKRAs, setLocalKRAs] = useState<KRA[]>([]);
  const [editingNewItem, setEditingNewItem] = useState<string | null>(null);
  const [localKPIs, setLocalKPIs] = useState<any[]>([]);
  const [localCompetencies, setLocalCompetencies] = useState<string[]>([]);
  const [localGoals, setLocalGoals] = useState<Goal[]>([]);
  const [isDecisionsExpanded, setIsDecisionsExpanded] = useState(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(10), shift(), flip()],
  });

  const { departments } = useKPI();

  const getRoleFromKPIContext = useCallback((roleId: string) => {
    for (const department of departments) {
      const role = department.roles.find(r => r.id.toString() === roleId);
      if (role) {
        return role;
      }
    }
    return null;
  }, [departments]);

  useEffect(() => {
    setName(employeeDetails.employee.name);
  }, [employeeDetails]);

  useEffect(() => {
    const updateSidebarPosition = () => {
      const header = document.querySelector('.app-header');
      const navbar = document.querySelector('nav');
      if (header && navbar) {
        const totalOffset = header.clientHeight;
        setSidebarTop(totalOffset);
      }
    };

    updateSidebarPosition();
    window.addEventListener('resize', updateSidebarPosition);

    // Trigger animation after a short delay
    const timer = setTimeout(() => setIsVisible(true), 50);

    return () => {
      window.removeEventListener('resize', updateSidebarPosition);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        if (!user?.company) return;
        
        const response = await api.get(`/api/employees/${user.company}/`);
        setAllEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchEmployees();
  }, [user?.company]);

  useEffect(() => {
    if (employeeDetails?.role?.kras) {
      setLocalKRAs(employeeDetails.role.kras);
    }
  }, [employeeDetails?.role?.kras]);

  useEffect(() => {
    if (employeeDetails?.role?.kpis) {
      setLocalKPIs(employeeDetails.role.kpis);
    }
    if (employeeDetails?.role?.competencies) {
      setLocalCompetencies(employeeDetails.role.competencies);
    }
  }, [employeeDetails?.role?.kpis, employeeDetails?.role?.competencies]);

  useEffect(() => {
    if (employeeDetails?.employee?.competencies) {
      setLocalCompetencies(employeeDetails.employee.competencies);
    }
  }, [employeeDetails?.employee?.competencies]);

  useEffect(() => {
    if (employeeDetails?.role?.goals) {
      setLocalGoals(employeeDetails.role.goals);
    } else {
      const roleFromKPI = getRoleFromKPIContext(node.id);
      if (roleFromKPI?.goals) {
        setLocalGoals(roleFromKPI.goals);
      }
    }
  }, [employeeDetails?.role?.goals, node.id, getRoleFromKPIContext]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const refreshEmployeeDetails = async (employeeCode: string | null) => {
    if (!employeeCode) return;
    
    try {
      const response = await api.get(`/api/employee-dashboard/${employeeCode}/`);
      if (response.data) {
        const employeeData: EmployeeData = response.data;
        
        const roleFromKPI = getRoleFromKPIContext(node.id);
        
        setLocalKRAs(employeeData.role.kras || []);
        setLocalKPIs(employeeData.role.kpis || []);
        setLocalGoals(roleFromKPI?.goals || employeeData.role.goals || []);
        setLocalCompetencies(employeeData.employee.competencies || []);
      }
    } catch (error) {
      console.error('Error refreshing employee details:', error);
    }
  };

  const handleEmployeeUpdate = async (newEmployee: any) => {
    try {
      const response = await api.post('api/update-employee-role/', {
        roleId: node.id,
        employeeId: newEmployee.employee_id,
        userId: userId,
        previousRole: employeeDetails.role.title,
        employeeName: newEmployee.name,
        newRole: employeeDetails.role.title
      });

      if (response.data && response.data.role) {
        onUpdate({
          name: response.data.role.current_employee.name,
          role: response.data.role.title
        });
        setIsEmployeeSelectorOpen(false);
        
        // Refresh both org chart and employee details
        onDataChange?.();
        await refreshEmployeeDetails(newEmployee.employee_id);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  const handleRemoveEmployee = async () => {
    try {
      const response = await api.post('api/remove-employee-role/', {
        roleId: node.id,
        employeeId: employeeDetails.employee.employee_id,
        userId: userId
      });

      if (response.data && response.data.role) {
        onUpdate({
          name: '',
          role: response.data.role.title
        });
        setIsEmployeeSelectorOpen(false);
        
        // Clear local state and refresh org chart
        setLocalKRAs([]);
        setLocalKPIs([]);
        setLocalGoals([]);
        setLocalCompetencies([]);
        onDataChange?.();
      }
    } catch (error) {
      console.error('Error removing employee:', error);
    }
  };

  const handleAddKRA = async () => {
    const tempId = createTempId();
    const newKRA = {
      id: tempId,
      description: "",
      responsibilities: []
    };

    setLocalKRAs(prev => [...prev, newKRA]);
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kra/`, {
        role: node.id,
        description: "",
        userId: userId,
      });

      setLocalKRAs(prev => prev.map(kra => 
        kra.id === tempId ? { ...response.data, responsibilities: [] } : kra
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding KRA:', error);
      setLocalKRAs(prev => prev.filter(kra => kra.id !== tempId));
      setEditingNewItem(null);
    }
  };

  const handleEditKRA = async (kraId: string, description: string) => {
    const previousKRA = localKRAs.find(kra => kra.id === kraId);
    
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId ? { ...kra, description } : kra
    ));

    try {
      await api.put(`/api/kra/${kraId}/`, {
        description,
        previousDescription: previousKRA?.description,
        userId: userId,
        roleId: node.id
      });
    } catch (error) {
      console.error('Error updating KRA:', error);
      setLocalKRAs(employeeDetails.role.kras);
    }
  };

  const handleDeleteKRA = async (kraId: string) => {
    // Optimistically update the UI
    const previousKRAs = [...localKRAs];
    setLocalKRAs(prev => prev.filter(kra => kra.id !== kraId));

    try {
      await api.delete(`/api/kra/${kraId}/delete/`, {
        data: { userId: userId }
      });
    } catch (error) {
      console.error('Error deleting KRA:', error);
      // Revert on error
      setLocalKRAs(previousKRAs);
    }
  };

  const handleAddResponsibility = async (kraId: string) => {
    const tempId = createTempId();
    const newResp = {
      id: tempId,
      description: ""
    };

    // Optimistically update UI first
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId 
        ? { 
            ...kra, 
            responsibilities: [...(kra.responsibilities || []), newResp]
          }
        : kra
    ));
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kra/${kraId}/responsibility/`, {
        description: "",
        userId: userId,
      });

      // Update with real data from response, only using id and description
      setLocalKRAs(prev => prev.map(kra => 
        kra.id === kraId 
          ? {
              ...kra,
              responsibilities: kra.responsibilities.map(resp => 
                resp.id === tempId 
                  ? { 
                      id: response.data.id,
                      description: response.data.description
                    }
                  : resp
              )
            }
          : kra
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding responsibility:', error);
      // Revert on error
      setLocalKRAs(prev => prev.map(kra => 
        kra.id === kraId 
          ? { 
              ...kra, 
              responsibilities: kra.responsibilities.filter(r => r.id !== tempId)
            }
          : kra
      ));
      setEditingNewItem(null);
    }
  };

  const handleEditResponsibility = async (kraId: string, index: number, description: string) => {
    // Optimistically update the UI
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId 
        ? {
            ...kra,
            responsibilities: kra.responsibilities.map((resp: Responsibility, i: number) => 
              i === index ? { ...resp, description } : resp
            )
          }
        : kra
    ));

    try {
      await api.put(`/api/kra/${kraId}/responsibility/${index}/`, {
        description,
        userId: userId,
      });
    } catch (error) {
      console.error('Error updating responsibility:', error);
      // Revert on error
      setLocalKRAs(employeeDetails.role.kras);
    }
  };

  const handleDeleteResponsibility = async (kraId: string, index: number) => {
    // Optimistically update the UI
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId 
        ? { 
            ...kra, 
            responsibilities: kra.responsibilities.filter((_: Responsibility, i: number) => i !== index) 
          }
        : kra
    ));

    try {
      await api.delete(`/api/kra/${kraId}/responsibility/${index}/`, {
        data: { userId: userId }
      });
    } catch (error) {
      console.error('Error deleting responsibility:', error);
      // Revert on error
      setLocalKRAs(employeeDetails.role.kras);
    }
  };

  const handleAddKPI = async () => {
    const tempId = createTempId();
    const newKPI = {
      id: tempId,
      description: "",
      needs_review: false
    };

    // Optimistically add to UI
    setLocalKPIs(prev => [...prev, newKPI]);
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kpi/`, {
        role: node.id,
        description: "",
        userId: userId,
      });

      // Update with real data from response
      setLocalKPIs(prev => prev.map(kpi => 
        kpi.id === tempId ? response.data : kpi
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding KPI:', error);
      // Remove on error
      setLocalKPIs(prev => prev.filter(kpi => kpi.id !== tempId));
      setEditingNewItem(null);
    }
  };

  const handleEditKPI = async (kpiId: string, description: string) => {
    const previousKPI = localKPIs.find(kpi => kpi.id === kpiId);
    
    // Optimistically update UI
    setLocalKPIs(prev => prev.map(kpi => 
      kpi.id === kpiId ? { ...kpi, description } : kpi
    ));

    try {
      const response = await api.put(`/api/kpi/${kpiId}/`, {
        description,
        previousDescription: previousKPI?.description,
        userId: userId,
        roleId: node.id
      });

      // Update with response data to ensure we have latest state
      setLocalKPIs(prev => prev.map(kpi => 
        kpi.id === kpiId ? response.data : kpi
      ));
    } catch (error) {
      console.error('Error updating KPI:', error);
      // Revert on error
      setLocalKPIs(employeeDetails.role.kpis);
    }
  };

  const handleDeleteKPI = async (kpiId: string) => {
    const previousKPIs = [...localKPIs];
    setLocalKPIs(prev => prev.filter(kpi => kpi.id !== kpiId));

    try {
      await api.delete(`/api/kpi/${kpiId}/`, {
        data: { userId: userId }
      });
    } catch (error) {
      console.error('Error deleting KPI:', error);
      setLocalKPIs(previousKPIs);
    }
  };

  const handleAddCompetency = async () => {
    try {
      const response = await api.post('/api/competency/', {
        employee_id: employeeDetails.employee.employee_id,
        competency: "New Competency"
      });

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
        setEditingNewItem(null);
      }
    } catch (error) {
      console.error('Error adding competency:', error);
    }
  };

  const handleEditCompetency = async (index: number, newValue: string) => {
    try {
      const response = await api.put(`/api/competency/${employeeDetails.employee.employee_id}/${index}/`, {
        competency: newValue
      });

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
        setEditingNewItem(null);
      }
    } catch (error) {
      console.error('Error updating competency:', error);
    }
  };

  const handleDeleteCompetency = async (index: number) => {
    try {
      const response = await api.delete(`/api/competency/delete/${employeeDetails.employee.employee_id}/${index}/`);

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
      }
    } catch (error) {
      console.error('Error deleting competency:', error);
    }
  };

  if (!employeeDetails || !employeeDetails.employee) {
    return (
      <div 
        ref={sidebarRef}
        className={`fixed right-0 w-1/2 shadow-lg overflow-y-auto z-50 bg-[#FFF5D6] transition-transform duration-300 ease-in-out ${
          isVisible ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ 
          top: `${sidebarTop}px`,
          height: `calc(100vh - ${sidebarTop}px)`,
        }}
      >
        <div className="sticky top-0 bg-black z-20 px-6 py-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-white">
              {employeeDetails?.employee ? 'Employee Details' : 'Vacant Position'}
            </h2>
            <button 
              onClick={handleClose} 
              className="text-primary-foreground hover:text-primary-foreground/80"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        <div className="p-6 space-y-6">
          <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6 space-y-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-muted-foreground">Position Details</label>
              <div className="flex items-center">
                <button
                  ref={refs.setReference}
                  onClick={() => setIsEmployeeSelectorOpen(!isEmployeeSelectorOpen)}
                  className="flex-grow flex items-center justify-between px-3 py-2 border rounded-md hover:bg-muted/20"
                >
                  <span className="text-lg font-semibold text-red-500 italic hover:text-red-600">
                    Vacant Position
                  </span>
                  <Edit2 size={18} className="text-muted-foreground" />
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-muted-foreground">Role</label>
              <div className="flex items-center">
                <span className="flex-grow text-muted-foreground">{employeeDetails.role.title}</span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className="flex items-center">
                <Briefcase size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.department}</span>
              </div>
              <div className="flex items-center">
                <MapPin size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.location}</span>
              </div>
              <div className="flex items-center">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.grade}</span>
              </div>
            </div>
          </div>
          
          {isEmployeeSelectorOpen && createPortal(
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="z-50"
            >
              <EmployeeSelector
                selectedEmployee={null}
                onUpdate={handleEmployeeUpdate}
                onClose={() => setIsEmployeeSelectorOpen(false)}
                allEmployees={allEmployees}
              />
            </div>,
            document.body
          )}
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={sidebarRef}
      className={`fixed right-0 w-1/2 shadow-lg overflow-y-auto z-50 bg-[#FFF5D6] transition-transform duration-300 ease-in-out ${
        isVisible ? 'translate-x-0' : 'translate-x-full'
      }`}
      style={{ 
        top: `${sidebarTop}px`,
        height: `calc(100vh - ${sidebarTop}px)`,
      }}
    >
      <div className="sticky top-0 bg-black z-20 px-6 py-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-white">
            {employeeDetails?.employee ? 'Employee Details' : 'Vacant Position'}
          </h2>
          <button 
            onClick={handleClose} 
            className="text-primary-foreground hover:text-primary-foreground/80"
          >
            <X size={24} />
          </button>
        </div>
      </div>

      <div className="p-6 space-y-6">
        {/* Business Card Style Employee Details */}
        <div className="bg-background border border-black rounded-lg shadow-sm p-6 space-y-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium text-muted-foreground">Name</label>
            <div className="flex items-center">
              <button
                ref={refs.setReference}
                onClick={() => setIsEmployeeSelectorOpen(!isEmployeeSelectorOpen)}
                className="flex-grow flex items-center justify-between px-3 py-2 border rounded-md hover:bg-muted/20"
              >
                <span className="text-lg font-semibold">
                  {employeeDetails.employee.name || 'Vacant Position'}
                </span>
                <Edit2 size={18} className="text-muted-foreground" />
              </button>
              
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button
                    className="p-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                    title="Remove employee from role"
                  >
                    <MinusCircle size={20} />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Remove Employee from Role</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to remove {employeeDetails.employee.name} from the role of {employeeDetails.role.title}? 
                      This will mark the position as vacant.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction 
                      onClick={handleRemoveEmployee}
                      className="bg-red-600 hover:bg-red-700 text-white"
                    >
                      Remove
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="role" className="text-sm font-medium text-muted-foreground">Role</label>
            <div className="flex items-center">
              <span className="flex-grow text-muted-foreground">{employeeDetails.role.title}</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm">
            {employeeDetails.role.current_employee && (
              <>
                <div className="flex items-center">
                  <Briefcase className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                  <span className="text-muted-foreground font-medium">ID:</span>
                  <span className="ml-2">{employeeDetails.role.current_employee.employee_id}</span>
                </div>
                
                {/* Department hierarchy section */}
                <div className="flex items-start col-span-2">
                  <Users className="h-5 w-5 mr-2 mt-1 text-black" strokeWidth={2.5} />
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <span className="text-muted-foreground font-medium">Department:</span>
                      <span className="ml-2">{employeeDetails.role.department}</span>
                    </div>
                    {employeeDetails.role.sub_department_level_1 && (
                      <div className="flex items-center ml-4 mt-1">
                        <span className="text-muted-foreground font-medium">→</span>
                        <span className="ml-2">{employeeDetails.role.sub_department_level_1}</span>
                      </div>
                    )}
                    {employeeDetails.role.sub_department_level_2 && (
                      <div className="flex items-center ml-8 mt-1">
                        <span className="text-muted-foreground font-medium">→</span>
                        <span className="ml-2">{employeeDetails.role.sub_department_level_2}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                {employeeDetails.role.current_employee.email && employeeDetails.role.current_employee.email.trim() !== '' && (
                  <div className="flex items-center">
                    <Mail className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                    <span className="text-muted-foreground font-medium">Email:</span>
                    <span className="ml-2">{employeeDetails.role.current_employee.email}</span>
                  </div>
                )}
                
                {employeeDetails.role.location && employeeDetails.role.location.trim() !== '' && (
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                    <span className="text-muted-foreground font-medium">Location:</span>
                    <span className="ml-2">{employeeDetails.role.location}</span>
                  </div>
                )}
                
                <div className="flex items-center">
                  <Users className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                  <span className="text-muted-foreground font-medium">Grade:</span>
                  <span className="ml-2">{employeeDetails.role.grade}</span>
                </div>
                
                {employeeDetails.role.cost_center && employeeDetails.role.cost_center.trim() !== '' && (
                  <div className="flex items-center">
                    <Briefcase className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                    <span className="text-muted-foreground font-medium">Cost Center:</span>
                    <span className="ml-2">{employeeDetails.role.cost_center}</span>
                  </div>
                )}
                
                {employeeDetails.role.current_employee.date_of_joining && (
                  <div className="flex items-center col-span-2">
                    <Calendar className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                    <span className="text-muted-foreground font-medium">Joined:</span>
                    <span className="ml-2">
                      {new Date(employeeDetails.role.current_employee.date_of_joining).toLocaleDateString()}
                    </span>
                  </div>
                )}
                {employeeDetails.role.reporting_to?.current_employee && (
                  <div className="flex items-center col-span-2">
                    <UserPlus className="h-5 w-5 mr-2 text-black" strokeWidth={2.5} />
                    <span className="text-muted-foreground font-medium">Reports to:</span>
                    <span className="ml-2">{employeeDetails.role.reporting_to.current_employee.name}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* KRAs Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsKRAsExpanded(!isKRAsExpanded)}
            >
              {isKRAsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <ClipboardList size={20} className="ml-2 mr-2" />
              <span className="ml-2">Key Result Areas (KRAs)</span>
              <span className="ml-2 text-sm text-muted-foreground">
                ({localKRAs?.length || 0})
              </span>
            </button>
            {isKRAsExpanded && (
              <button
                onClick={handleAddKRA}
                className="p-1 hover:bg-muted rounded-md"
                title="Add KRA"
              >
                <PlusCircle size={20} />
              </button>
            )}
          </div>
          {isKRAsExpanded && (
            <div className="space-y-4">
              {localKRAs?.map((kra: KRA) => (
                <div key={kra.id} className="space-y-2">
                  <EditableItem
                    item={kra}
                    itemId={kra.id}
                    onEdit={(description) => handleEditKRA(kra.id, description)}
                    onDelete={() => handleDeleteKRA(kra.id)}
                    editingNewItem={editingNewItem}
                    setEditingNewItem={setEditingNewItem}
                  >
                    {kra.responsibilities?.length > 0 && (
                      <>
                        <div className="mt-3 mb-2 border-t border-border/50" />
                        <div className="space-y-2">
                          <p className="text-xs font-medium text-muted-foreground ml-2">
                            Responsibilities:
                          </p>
                          <div className="pl-3 space-y-2">
                            {kra.responsibilities.map((resp: Responsibility, index: number) => (
                              <EditableItem
                                key={resp.id || index}
                                item={resp}
                                itemId={resp.id}
                                onEdit={(description) => handleEditResponsibility(kra.id, index, description)}
                                onDelete={() => handleDeleteResponsibility(kra.id, index)}
                                editingNewItem={editingNewItem}
                                setEditingNewItem={setEditingNewItem}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <button
                      onClick={() => handleAddResponsibility(kra.id)}
                      className="mt-2 flex items-center gap-1 text-xs text-muted-foreground hover:text-foreground ml-3"
                    >
                      <PlusCircle size={14} />
                      <span>Add Responsibility</span>
                    </button>
                  </EditableItem>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* KPIs Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsKPIsExpanded(!isKPIsExpanded)}
            >
              {isKPIsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <LineChart size={20} className="ml-2 mr-2" />
              <span className="ml-2">Key Performance Indicators (KPIs)</span>
              <span className="ml-2 text-sm text-muted-foreground">
                ({localKPIs?.length || 0})
              </span>
            </button>
            {isKPIsExpanded && (
              <button
                onClick={handleAddKPI}
                className="p-1 hover:bg-muted rounded-md"
                title="Add KPI"
              >
                <PlusCircle size={20} />
              </button>
            )}
          </div>
          {isKPIsExpanded && (
            <div className="space-y-4">
              {localKPIs?.map((kpi) => (
                <EditableItem
                  key={kpi.id}
                  item={kpi}
                  itemId={kpi.id}
                  onEdit={(description) => handleEditKPI(kpi.id, description)}
                  onDelete={() => handleDeleteKPI(kpi.id)}
                  editingNewItem={editingNewItem}
                  setEditingNewItem={setEditingNewItem}
                />
              ))}
            </div>
          )}
        </div>

        {/* Decisions Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsDecisionsExpanded(!isDecisionsExpanded)}
            >
              {isDecisionsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <FileText size={20} className="ml-2 mr-2" />
              <span>Decisions</span>
              <span className="ml-2 text-sm text-muted-foreground">
                ({employeeDetails.decisions?.length || 0})
              </span>
            </button>
          </div>
          {isDecisionsExpanded && (
            <div className="space-y-4">
              {employeeDetails.decisions && employeeDetails.decisions.length > 0 ? (
                <div className="space-y-2">
                  {employeeDetails.decisions.map((decision: any, index: number) => (
                    <div key={index} className="bg-muted p-3 rounded-md">
                      <p className="font-medium text-sm">{decision.description}</p>
                      <p className="text-xs text-muted-foreground mt-1">Status: {decision.status}</p>
                      <p className="text-xs text-muted-foreground">
                        {new Date(decision.date).toLocaleDateString()}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted-foreground text-center py-4">No decisions available</p>
              )}
            </div>
          )}
        </div>

        {/* Goals Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsGoalsExpanded(!isGoalsExpanded)}
            >
              {isGoalsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <Target size={20} className="ml-2 mr-2" />
              <span>Goals</span>
              <span className="ml-2 text-sm text-muted-foreground">
                ({localGoals?.length || 0})
              </span>
            </button>
          </div>
          {isGoalsExpanded && (
            <div className="space-y-4">
              {localGoals?.map((goal) => (
                <div key={goal.id} className="bg-muted p-4 rounded-md space-y-2">
                  <h4 className="font-medium">{goal.title || goal.description}</h4>
                  <div className="space-y-1">
                    <div className="w-full bg-background rounded-full h-2 border border-border">
                      <div 
                        className="bg-primary h-full rounded-full transition-all min-w-[2px]"
                        style={{ width: `${goal.progress}%` }}
                      />
                    </div>
                    <div className="flex justify-between text-sm text-muted-foreground">
                      <span>{goal.progress}% complete</span>
                    </div>
                  </div>
                </div>
              ))}
              {(!localGoals || localGoals.length === 0) && (
                <p className="text-muted-foreground text-center py-4">No goals available</p>
              )}
            </div>
          )}
        </div>

        {/* Competencies Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsCompetenciesExpanded(!isCompetenciesExpanded)}
            >
              {isCompetenciesExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <Brain size={20} className="ml-2 mr-2" />
              <span>Competencies</span>
              <span className="ml-2 text-sm text-muted-foreground">
                ({localCompetencies?.length || 0})
              </span>
            </button>
            {isCompetenciesExpanded && (
              <button
                onClick={handleAddCompetency}
                className="p-1 hover:bg-muted rounded-md"
                title="Add Competency"
              >
                <PlusCircle size={20} />
              </button>
            )}
          </div>
          {isCompetenciesExpanded && (
            <div className="space-y-4">
              {localCompetencies?.map((competency, index) => (
                <EditableItem
                  key={index}
                  item={{ description: competency, id: index.toString() }}
                  itemId={index.toString()}
                  onEdit={(description) => handleEditCompetency(index, description)}
                  onDelete={() => handleDeleteCompetency(index)}
                  editingNewItem={editingNewItem}
                  setEditingNewItem={setEditingNewItem}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Move the portal outside the main content but still inside the sidebar */}
      {isEmployeeSelectorOpen && createPortal(
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="z-[100]" // Increased z-index
        >
          <EmployeeSelector
            selectedEmployee={employeeDetails.employee}
            onUpdate={handleEmployeeUpdate}
            onClose={() => setIsEmployeeSelectorOpen(false)}
            allEmployees={allEmployees}
          />
        </div>,
        document.body
      )}
    </div>
  );
};

export default NodeSidebar;
