import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';

interface Employee {
  id: string;
  name: string;
  email: string;
  employee_code: string;
}

interface SecondaryManager {
  id: string;
  name: string;
  title: string;
  department: string;
  employee_code: string;
}

interface CustomNodeData {
  title: string;
  department: string;
  sub_department_level_1?: string;
  sub_department_level_2?: string;
  cost_center?: string;
  color: string;
  isIc: boolean;
  grade: string;
  location: string;
  currentEmployee: Employee | null;
  secondaryManager: SecondaryManager | null;
  width: number;
  isClickable?: boolean;
}

const CustomNode: React.FC<NodeProps<CustomNodeData>> = ({ data, id }) => {
  // Check if this is the virtual root node
  const isVirtualRoot = id === 'virtual_root';

  if (isVirtualRoot) {
    return (
      <div 
        style={{ 
          background: '#f8f9fa',
          border: '2px solid #dee2e6',
          borderRadius: '8px',
          padding: '12px',
          width: '220px',
          minHeight: '60px', // Smaller height for company node
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          fontFamily: 'Inter, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#495057',
          textAlign: 'center',
        }}>
          {data.title}
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: '#555',
            width: '8px',
            height: '8px',
          }}
        />
      </div>
    );
  }

  // Regular node rendering
  return (
    <div 
      style={{ 
        background: 'white',
        borderTop: `4px solid ${data.color}`,
        borderLeft: `4px solid ${data.color}`,
        borderRadius: '4px',
        padding: '10px 12px',
        width: '220px',
        height: '110px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
      className="node"
    >
      {/* Main content */}
      <div className="space-y-1">
        {/* Employee Name / Vacant */}
        {data.currentEmployee ? (
          <div style={{ 
            fontSize: '16px',
            fontWeight: '600',
            color: '#1a1a1a',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: '4px',
          }}>
            {data.currentEmployee.name}
          </div>
        ) : (
          <div style={{ 
            fontSize: '16px',
            color: '#dc3545',
            fontStyle: 'italic',
            marginBottom: '4px',
          }}>
            Vacant Position
          </div>
        )}

        {/* Role Title */}
        <div style={{ 
          fontSize: '14px',
          fontWeight: '500',
          color: '#2d3748',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingLeft: '4px',
          marginBottom: '4px',
        }}>
          {data.title}
        </div>

        {/* Department with icon */}
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          fontSize: '12px',
          color: '#4a5568',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect>
            <line x1="12" y1="6" x2="12" y2="18"></line>
            <line x1="8" y1="6" x2="8" y2="18"></line>
            <line x1="16" y1="6" x2="16" y2="18"></line>
          </svg>
          <span>{data.department}</span>
        </div>
      </div>

      {/* Handles */}
      <Handle
        type="target"
        position={Position.Top}
        style={{
          background: '#555',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: '#555',
          width: '8px',
          height: '8px',
        }}
      />
    </div>
  );
};

export default memo(CustomNode);
